<template>
    <AuthLayout>
        <template #form>
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Alterar senha</h2>
            <h6 class="subtitle-1 mb-5">Digite e confirme sua nova senha</h6>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="form.password" :rules="[rules.password]" label="Senha" dense name="password" required
                    outlined :loading="isLoading" :disabled="isLoading" @click:append="() => (show1 = !show1)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"></v-text-field>
                <v-text-field v-model="form.passwordConfirm" :rules="[rules.password, (form.password === form.passwordConfirm)]" label="Confirmar senha" dense
                    name="password" required outlined :loading="isLoading" :disabled="isLoading"
                    @click:append="() => (show2 = !show2)" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"></v-text-field>
                <v-btn :disabled="!valid || isLoading" color="info" block class="mr-4" submit outlined :loading="isLoading"
                    @click="submit">Enviar</v-btn>
            </v-form>
        </template>
    </AuthLayout>
</template>

<script>
import { handleError } from '@/utils/helpers'

export default {
  data: () => ({
    valid: true,
    show1: false,
    show2: false,
    form: {
      password: '',
      passwordConfirm: ''
    },
    rules: {
      password: v => (v && !!v.trim()) || 'A senha é obrigatória.'
    },
    checkbox: false,
    isLoading: false
  }),
  methods: {
    handleError,
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      const payload = {
        password: this.form.password,
        password_confirmation: this.form.passwordConfirm
      }
      this.isLoading = true
      const { userId, organizationId } = this.$route.params
      this.$axios.patch(`/organizations/${organizationId}/users/${userId}`, payload)
        .then(response => {
          this.$store.dispatch('alert', { msg: 'Senha alterada com sucesso' })
          this.$router.push('/login')
        })
        .catch(error => {
          this.handleError(error)
        })
        .finally(() => {
          this.isLoading = false
          this.show1 = false
          this.show2 = false
        })
    }
  }
}
</script>
